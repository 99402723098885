import { useContext, useEffect } from "react"
import {
    DialogActions,
    Button,
    DialogContent,
    CircularProgress,
    Typography,
    DialogTitle,
    Box,
    Grid,
    TextField,
    Divider,
    MenuItem,
    FormControlLabel,
    Checkbox,
    Drawer,
} from "@mui/material"
import { Controller, useForm } from "react-hook-form"
import { useMutation, useQuery } from "@tanstack/react-query"
import { Add } from "@mui/icons-material"
import { useSnackbar } from "notistack"
import { DateTime } from "luxon"

import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import { FREQUENCIES } from "../../utils/config"
import useBreakPoints from "../../hooks/useBreakPoints"
import AuthContext from "../../context/authProvider"
import CustomDateTimePicker from "../../component/customDateTimePicker"

interface AddPricesProps {
    productId: string | undefined
    openDialog: boolean
    handleClosePricingDialog: () => void
    handleCloseProductDialog?: () => void
    refetch?: () => void
}
interface PriceForm {
    type: string
    name: string
    amount: number
    currency_id: string
    frequency_interval: number
    frequency: string
    recurring_cycle: number
    grace_period: number
    expiration: string
    immediate_cancel_require: string
}

function AddPrices(props: AddPricesProps) {
    const {
        productId,
        openDialog,
        handleClosePricingDialog,
        handleCloseProductDialog,
        refetch,
    } = props

    const { merchantId } = useContext(AuthContext) as any

    const axiosPrivate = useAxiosPrivate()

    const { sm } = useBreakPoints()

    // USESTATE
    // const [value, setValue] = useState("recurring")
    // HOOK FORM
    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<PriceForm>({ mode: "onSubmit" })
    // const { fields, append, remove } = useFieldArray<PriceForm>({
    //     control,
    //     name: "prices",
    // })

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar()

    // MUTATION
    const { isLoading, mutate, data } = useMutation(
        (formData) =>
            axiosPrivate.post(
                `/merchants/${merchantId}/products/${productId}/prices`,
                formData
            ),
        {
            onError: (error: any) => {
                const fieldError =
                    error.response?.data?.error?.field_error?.[0]?.description
                const errorMessage = error.response?.data?.error?.message
                enqueueSnackbar(
                    fieldError || errorMessage || "Request Failed",
                    {
                        variant: "error",
                    }
                )
            },
        }
    )
    const { isLoading: loading, data: currencies } = useQuery({
        queryKey: [],
        queryFn: () =>
            axiosPrivate
                .get(`/currencies`, {
                    params: {
                        per_page: -1,
                    },
                })
                .then((response) => response.data),

        onError: (error: any) => {
            const fieldError =
                error.response?.data?.error?.field_error?.[0]?.description
            const errorMessage = error.response?.data?.error?.message
            enqueueSnackbar(fieldError || errorMessage || "Request Failed", {
                variant: "error",
            })
        },
    })

    // HELPERS and EVENT HANDLERS
    // const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    //     setValue(newValue)
    // }
    const onSubmit = (form: any) => {
        mutate({
            ...form,
            immediate_cancel_require: form.immediate_cancel_require
                ? "YES"
                : "NO",
            grace_period: form.grace_period + "h",
            expiration: DateTime.fromISO(form.expiration)
                .toUTC()
                .toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'"),
        })
    }

    // USEEFFECT
    useEffect(() => {
        if (data?.data.ok) {
            if (handleCloseProductDialog) {
                handleCloseProductDialog()
            }
            if (refetch) {
                refetch()
            }
            handleClosePricingDialog()
        }
    }, [data?.data.ok])

    // RENDER
    return (
        <Drawer
            open={openDialog}
            anchor="right"
            onClose={handleClosePricingDialog}
            PaperProps={{
                style: {
                    width: sm ? "50%" : "100%",
                },
            }}
        >
            <DialogTitle
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    bgcolor: "#fafafa",
                }}
            >
                <Box>
                    <Typography
                        sx={{
                            fontSize: 20,
                            fontWeight: "bold",
                            textAlign: "center",
                        }}
                    >
                        {" "}
                        Add Price
                    </Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                {loading ? (
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <CircularProgress
                            size={30}
                            sx={{
                                color: "black",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                marginTop: "-12px",
                                marginLeft: "-12px",
                            }}
                        />
                    </Box>
                ) : (
                    <Box
                        sx={{
                            mx: 2,
                            display: "flex",
                            flexDirection: "column",
                            // width: "100%",
                        }}
                    >
                        <Typography
                            sx={{ fontSize: 18, fontWeight: "bold", my: 2 }}
                        >
                            Price(s)
                        </Typography>
                        <Divider />
                        <Grid container columnSpacing={3} sx={{ my: 1 }}>
                            {/* <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                justifyContent="flex-end"
                            >
                                <Button
                                    sx={{
                                        justifyContent: "flex-end",
                                        textTransform: "none",
                                    }}
                                    onClick={() => remove(index)}
                                >
                                    <Tooltip title="Remove price form">
                                        <Delete sx={{ color: "red" }} />
                                    </Tooltip>
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <Typography>Choose Type</Typography>
                                <Box sx={{ width: "100%" }}>
                                    <Tabs
                                        value={value}
                                        onChange={handleChange}
                                        aria-label="tabs"
                                    >
                                        <Tab
                                            value="recurring"
                                            label="Recurring"
                                        />
                                        <Tab value="flat" label="One Time" />
                                    </Tabs>
                                </Box>
                            </Grid> */}
                            <Grid item xs={12} sm={4} md={4}>
                                <Controller
                                    name="name"
                                    control={control}
                                    rules={{
                                        required: "Price name can't be empty.",
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            id="price_name"
                                            variant="outlined"
                                            label="Name"
                                            margin="normal"
                                            fullWidth
                                            error={!!errors?.name}
                                            helperText={
                                                errors?.name
                                                    ? errors?.name?.message
                                                    : null
                                            }
                                            size="small"
                                            sx={{
                                                mt: 1.5,
                                                mb: 0.5,
                                                mr: 1,
                                                flex: 1,
                                            }}
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                                <Controller
                                    name="amount"
                                    control={control}
                                    rules={{
                                        required:
                                            "Price amount can't be empty.",
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            id="price_amount"
                                            variant="outlined"
                                            label="Amount"
                                            margin="normal"
                                            type="number"
                                            fullWidth
                                            error={!!errors?.amount}
                                            helperText={
                                                errors?.amount
                                                    ? errors?.amount?.message
                                                    : null
                                            }
                                            size="small"
                                            sx={{
                                                mt: 1.5,
                                                mb: 0.5,
                                                mr: -2,
                                                flex: 2,
                                            }}
                                            {...field}
                                            onChange={(e) =>
                                                field.onChange(
                                                    parseInt(e.target.value)
                                                )
                                            }
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                                <Controller
                                    name="currency_id"
                                    control={control}
                                    rules={{
                                        required:
                                            "Price currency can't be empty.",
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            id="price_currency"
                                            variant="outlined"
                                            label="Currency"
                                            margin="normal"
                                            fullWidth
                                            select
                                            error={!!errors?.currency_id}
                                            helperText={
                                                errors?.currency_id
                                                    ? errors?.currency_id
                                                          ?.message
                                                    : null
                                            }
                                            size="small"
                                            sx={{
                                                mt: 1.5,
                                                mb: 0.5,
                                                mr: 1,
                                                flex: 1,
                                            }}
                                            {...field}
                                        >
                                            {currencies?.data?.map(
                                                (item: any) => (
                                                    <MenuItem
                                                        value={item?.id}
                                                        key={item?.id}
                                                    >
                                                        {item.code}
                                                    </MenuItem>
                                                )
                                            )}
                                        </TextField>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                                <Controller
                                    name={`frequency`}
                                    control={control}
                                    rules={{
                                        required:
                                            "Price frequency can't be empty.",
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            id="price_frequency"
                                            variant="outlined"
                                            label="Frequency"
                                            margin="normal"
                                            fullWidth
                                            select
                                            error={!!errors?.frequency}
                                            helperText={
                                                errors?.frequency
                                                    ? errors?.frequency?.message
                                                    : null
                                            }
                                            size="small"
                                            sx={{
                                                mt: 1.5,
                                                mb: 0.5,
                                                mr: 1,
                                                flex: 1,
                                            }}
                                            {...field}
                                        >
                                            {FREQUENCIES.map(
                                                (
                                                    item: string,
                                                    index: number
                                                ) => (
                                                    <MenuItem
                                                        value={item}
                                                        key={index}
                                                    >
                                                        {item}
                                                    </MenuItem>
                                                )
                                            )}
                                        </TextField>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                                <Controller
                                    name="frequency_interval"
                                    control={control}
                                    rules={{
                                        required:
                                            "Price frequency interval can't be empty.",
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            id="price_frequency_interval"
                                            variant="outlined"
                                            label="Frequency Interval"
                                            margin="normal"
                                            fullWidth
                                            type="number"
                                            error={!!errors?.frequency_interval}
                                            helperText={
                                                errors?.frequency_interval
                                                    ? errors?.frequency_interval
                                                          ?.message
                                                    : null
                                            }
                                            size="small"
                                            sx={{
                                                mt: 1.5,
                                                mb: 0.5,
                                                mr: 1,
                                                flex: 1,
                                            }}
                                            {...field}
                                            onChange={(e) =>
                                                field.onChange(
                                                    parseInt(e.target.value)
                                                )
                                            }
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                                <Controller
                                    name="recurring_cycle"
                                    control={control}
                                    rules={{
                                        required:
                                            "Price recurring_cycle can't be empty.",
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            id="price_recurring_cycle"
                                            variant="outlined"
                                            label="Recurring Cycle"
                                            margin="normal"
                                            fullWidth
                                            type="number"
                                            error={!!errors?.recurring_cycle}
                                            helperText={
                                                errors?.recurring_cycle
                                                    ? errors?.recurring_cycle
                                                          ?.message
                                                    : null
                                            }
                                            size="small"
                                            sx={{
                                                mt: 1.5,
                                                mb: 0.5,
                                                mr: 1,
                                                flex: 1,
                                            }}
                                            {...field}
                                            onChange={(e) =>
                                                field.onChange(
                                                    parseInt(e.target.value)
                                                )
                                            }
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                                <Controller
                                    name="grace_period"
                                    control={control}
                                    rules={{
                                        required:
                                            "Price grace period can't be empty.",
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            id="price_grace_period"
                                            variant="outlined"
                                            label="Grace Period (Hr)"
                                            margin="normal"
                                            fullWidth
                                            type="number"
                                            error={!!errors?.grace_period}
                                            helperText={
                                                errors?.grace_period
                                                    ? errors?.grace_period
                                                          ?.message
                                                    : null
                                            }
                                            size="small"
                                            sx={{
                                                mt: 1.5,
                                                mb: 0.5,
                                                mr: 1,
                                                flex: 1,
                                            }}
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                            <Controller
                                name="expiration"
                                control={control}
                                rules={{
                                    required: "Price expire date can't be empty.",
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <CustomDateTimePicker
                                        onChange={onChange}
                                        label="Expire Date"
                                        value={value}
                                        date2={DateTime.now().toISO()}
                                        margin="normal"
                                        size="small"
                                    />
                                )}
                            />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            sx={{
                                                "&.Mui-checked": {
                                                    color: "#fbcf3b",
                                                },
                                            }}
                                        />
                                    }
                                    label="Immediate cancel required"
                                    {...register("immediate_cancel_require")}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} sx={{ mt: 3 }}>
                                <Divider />
                            </Grid>
                        </Grid>
                        <Box
                            sx={{
                                fontSize: 12,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                mt: 1.5,
                                mb: 2,
                            }}
                        >
                            <Button
                                variant="outlined"
                                sx={{
                                    color: "secondary.main",
                                    borderColor: "primary.main",
                                    bgcolor: "#fff",
                                    ":hover": {
                                        color: "secondary.main",
                                        borderColor: "primary.main",
                                        bgcolor: "#fff",
                                    },
                                    textTransform: "none",
                                }}
                                disabled
                                // onClick={(value: any) => append(value)}
                            >
                                <Add sx={{ mr: 0.5 }} />
                                Add Price Form
                            </Button>
                        </Box>
                    </Box>
                )}
                {/* TO DO: This code is left until the backend starts to accept 
                           adding multiple prices on a single request */}
                {/* <Box
                    sx={{
                        mx: 2,
                        display: "flex",
                        flexDirection: "column",
                        // width: "100%",
                    }}
                >
                    <Typography
                        sx={{ fontSize: 18, fontWeight: "bold", my: 2 }}
                    >
                        Price(s)
                    </Typography>
                    <Divider />
                    {fields.map((item, index) => (
                        <Grid
                            key={index}
                            container
                            columnSpacing={3}
                            sx={{ my: 1 }}
                        >
                            {/* <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                justifyContent="flex-end"
                            >
                                <Button
                                    sx={{
                                        justifyContent: "flex-end",
                                        textTransform: "none",
                                    }}
                                    onClick={() => remove(index)}
                                >
                                    <Tooltip title="Remove price form">
                                        <Delete sx={{ color: "red" }} />
                                    </Tooltip>
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <Typography>Choose Type</Typography>
                                <Box sx={{ width: "100%" }}>
                                    <Tabs
                                        value={value}
                                        onChange={handleChange}
                                        aria-label="tabs"
                                    >
                                        <Tab
                                            value="recurring"
                                            label="Recurring"
                                        />
                                        <Tab value="flat" label="One Time" />
                                    </Tabs>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        fontWeight: "bold",
                                        mt: 1,
                                    }}
                                >
                                    Price {index + 1}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                                <Controller
                                    key={item.id}
                                    name={`prices.${index}.name`}
                                    control={control}
                                    rules={{
                                        required: "Price name can't be empty.",
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            key={`prices[${index}].name`}
                                            id="price_name"
                                            variant="outlined"
                                            label="Name"
                                            margin="normal"
                                            fullWidth
                                            error={
                                                !!errors?.prices?.[index]?.name
                                            }
                                            helperText={
                                                errors?.prices?.[index]?.name
                                                    ? errors.prices?.[index]
                                                          ?.name?.message
                                                    : null
                                            }
                                            size="small"
                                            sx={{
                                                mt: 1.5,
                                                mb: 0.5,
                                                mr: 1,
                                                flex: 1,
                                            }}
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                                <Controller
                                    key={index}
                                    name={`prices.${index}.amount`}
                                    control={control}
                                    rules={{
                                        required:
                                            "Price amount can't be empty.",
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            key={`prices[${index}].amount`}
                                            id="price_amount"
                                            variant="outlined"
                                            label="Amount"
                                            margin="normal"
                                            type="number"
                                            fullWidth
                                            error={
                                                !!errors?.prices?.[index]
                                                    ?.amount
                                            }
                                            helperText={
                                                errors?.prices?.[index]?.amount
                                                    ? errors.prices?.[index]
                                                          ?.amount?.message
                                                    : null
                                            }
                                            size="small"
                                            sx={{
                                                mt: 1.5,
                                                mb: 0.5,
                                                mr: -2,
                                                flex: 2,
                                            }}
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                                <Controller
                                    key={item.id}
                                    name={`prices.${index}.currency`}
                                    control={control}
                                    rules={{
                                        required:
                                            "Price currency can't be empty.",
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            key={`prices[${index}].currency`}
                                            id="price_currency"
                                            variant="outlined"
                                            label="Currency"
                                            margin="normal"
                                            fullWidth
                                            error={
                                                !!errors?.prices?.[index]
                                                    ?.currency
                                            }
                                            helperText={
                                                errors?.prices?.[index]
                                                    ?.currency
                                                    ? errors.prices?.[index]
                                                          ?.currency?.message
                                                    : null
                                            }
                                            size="small"
                                            sx={{
                                                mt: 1.5,
                                                mb: 0.5,
                                                mr: 1,
                                                flex: 1,
                                            }}
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                                <Controller
                                    key={item.id}
                                    name={`prices.${index}.frequency`}
                                    control={control}
                                    rules={{
                                        required:
                                            "Price frequency can't be empty.",
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            key={`prices[${index}].frequency`}
                                            id="price_frequency"
                                            variant="outlined"
                                            label="Frequency"
                                            margin="normal"
                                            fullWidth
                                            error={
                                                !!errors?.prices?.[index]
                                                    ?.frequency
                                            }
                                            helperText={
                                                errors?.prices?.[index]
                                                    ?.frequency
                                                    ? errors.prices?.[index]
                                                          ?.frequency?.message
                                                    : null
                                            }
                                            size="small"
                                            sx={{
                                                mt: 1.5,
                                                mb: 0.5,
                                                mr: 1,
                                                flex: 1,
                                            }}
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                                <Controller
                                    key={item.id}
                                    name={`prices.${index}.recurring_cycle`}
                                    control={control}
                                    rules={{
                                        required:
                                            "Price recurring_cycle can't be empty.",
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            key={`prices[${index}].recurring_cycle`}
                                            id="price_recurring_cycle"
                                            variant="outlined"
                                            label="Recurring Cycle"
                                            margin="normal"
                                            fullWidth
                                            error={
                                                !!errors?.prices?.[index]
                                                    ?.recurring_cycle
                                            }
                                            helperText={
                                                errors?.prices?.[index]
                                                    ?.recurring_cycle
                                                    ? errors.prices?.[index]
                                                          ?.recurring_cycle
                                                          ?.message
                                                    : null
                                            }
                                            size="small"
                                            sx={{
                                                mt: 1.5,
                                                mb: 0.5,
                                                mr: 1,
                                                flex: 1,
                                            }}
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                                <Controller
                                    key={item.id}
                                    name={`prices.${index}.grace_period`}
                                    control={control}
                                    rules={{
                                        required:
                                            "Price grace_period can't be empty.",
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            key={`prices[${index}].grace_period`}
                                            id="price_grace_period"
                                            variant="outlined"
                                            label="Grace Period"
                                            margin="normal"
                                            fullWidth
                                            error={
                                                !!errors?.prices?.[index]
                                                    ?.grace_period
                                            }
                                            helperText={
                                                errors?.prices?.[index]
                                                    ?.grace_period
                                                    ? errors.prices?.[index]
                                                          ?.grace_period
                                                          ?.message
                                                    : null
                                            }
                                            size="small"
                                            sx={{
                                                mt: 1.5,
                                                mb: 0.5,
                                                mr: 1,
                                                flex: 1,
                                            }}
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                                <Controller
                                    key={item.id}
                                    name={`prices.${index}.expire_date`}
                                    control={control}
                                    rules={{
                                        required:
                                            "Price expire_date can't be empty.",
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            key={`prices[${index}].expire_date`}
                                            id="price_expire_date"
                                            variant="outlined"
                                            type="date"
                                            label="Expire Date"
                                            margin="normal"
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            error={
                                                !!errors?.prices?.[index]
                                                    ?.expire_date
                                            }
                                            helperText={
                                                errors?.prices?.[index]
                                                    ?.expire_date
                                                    ? errors.prices?.[index]
                                                          ?.expire_date?.message
                                                    : null
                                            }
                                            size="small"
                                            sx={{
                                                mt: 1.5,
                                                mb: 0.5,
                                                mr: 1,
                                                flex: 1,
                                            }}
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} sx={{ mt: 3 }}>
                                <Divider />
                            </Grid>
                        </Grid>
                    ))}
                    <Box
                        sx={{
                            fontSize: 12,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            mt: 1.5,
                            mb: 2,
                        }}
                    >
                        <Button
                            variant="outlined"
                            sx={{
                                color: "secondary.main",
                                borderColor: "primary.main",
                                bgcolor: "#fff",
                                ":hover": {
                                    color: "secondary.main",
                                    borderColor: "primary.main",
                                    bgcolor: "#fff",
                                },
                                textTransform: "none",
                            }}
                            onClick={(value: any) => append(value)}
                        >
                            <Add sx={{ mr: 0.5 }} />
                            Add Price Form
                        </Button>
                    </Box>
                </Box> */}
            </DialogContent>
            <DialogActions
                sx={{
                    mt: 1,
                    pr: 3,
                    py: 2,
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    bgcolor: "#fafafa",
                }}
            >
                <Button
                    size="small"
                    variant="contained"
                    sx={{
                        ":hover": { bgcolor: "#e4e4e4" },
                        backgroundColor: "#e4e4e4",
                        textTransform: "none",
                        fontWeight: "bold",
                        width: 120,
                        p: 1,
                    }}
                    onClick={handleClosePricingDialog}
                >
                    <Typography sx={{ color: "primary.main" }}>
                        Cancel
                    </Typography>
                </Button>
                <Button
                    size="small"
                    variant="contained"
                    sx={{
                        bgcolor: "primary.main",
                        ":hover": { bgcolor: "primary.main" },
                        textTransform: "none",
                        fontWeight: "bold",
                        width: 120,
                        p: 1,
                    }}
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                    disabled={isLoading || loading}
                >
                    <Typography sx={{ color: "secondary.main" }}>
                        Save
                    </Typography>
                    {isLoading && (
                        <CircularProgress
                            size={24}
                            sx={{
                                color: "black",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                marginTop: "-12px",
                                marginLeft: "-12px",
                            }}
                        />
                    )}
                </Button>
            </DialogActions>
        </Drawer>
    )
}
export default AddPrices
