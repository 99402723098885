import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Grid,
    IconButton,
    Paper,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material"
import { useMutation } from "@tanstack/react-query"
import { Controller, useForm } from "react-hook-form"

import useAxiosPrivate from "../hooks/useAxiosPrivate"
import { useNavigate } from "react-router-dom"
import { useSnackbar } from "notistack"
import { useState } from "react"
import { Close, ContentCopy } from "@mui/icons-material"

interface MerchantForm {
    name: string
    merchant_name: string
    phone_number: number
    email: string
    address: string
}

function Registration() {
    const navigate = useNavigate()

    const [secret, setSecret] = useState<{
        secret_token: string
        web_secret: string
    } | null>(null)
    const [copy, setCopy] = useState<boolean>(false)

    const {
        control,
        formState: { errors },
        handleSubmit,
    } = useForm<MerchantForm>()

    // Custom Hook
    const axiosPrivate = useAxiosPrivate()

    // Snackbar
    const { enqueueSnackbar } = useSnackbar()

    // MUTATION
    const { isLoading, mutate } = useMutation(
        (formData: any) => axiosPrivate.post(`/merchants`, formData),
        {
            onSuccess: (data) => {
                setSecret({
                    secret_token: data?.data?.data?.secret_token,
                    web_secret: data?.data?.data?.secret,
                })
                window.localStorage.setItem("registered", "true")
                window.localStorage.setItem("merchantId", data?.data?.data?.id)
            },
            onError: (error: any) => {
                const fieldError =
                    error?.response?.data?.error?.field_error[0]?.description
                const errorMessage = error?.response?.data?.error?.message
                enqueueSnackbar(
                    fieldError || errorMessage || "Request Failed",
                    { variant: "error" }
                )
                window.localStorage.setItem("registered", "false")
            },
        }
    )

    const onSubmit = (form: any) => {
        mutate(form)
    }

    return secret ? (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                gap: 2,
                bgcolor: "#fff",
            }}
        >
            <Box sx={{ width: "400px", maxWidth: "90%" }}>
                {copy ? (
                    <Alert variant="outlined" severity="success">
                        Success! <br />
                        You have successfully copied the API key. You are all
                        set to proceed.
                    </Alert>
                ) : (
                    <Alert severity="warning" sx={{ p: "1em" }}>
                        Please copy your credentials and store them securely—do
                        not share them with anyone. <br /> If you have security
                        concerns, contact our Help Center to generate new
                        credentials.
                    </Alert>
                )}
            </Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "400px",
                    maxWidth: "90%",
                }}
            >
                <TextField
                    variant="outlined"
                    value={secret}
                    type="password"
                    disabled={copy}
                    size="small"
                    sx={{
                        flexGrow: 1,
                        mr: 1,
                    }}
                />
                {copy ? (
                    <Tooltip title="Click to close">
                        <IconButton
                            onClick={() => {
                                setSecret(null)
                                navigate("/")
                            }}
                        >
                            <Close />
                        </IconButton>
                    </Tooltip>
                ) : (
                    <Tooltip title="Click to copy">
                        <IconButton
                            onClick={() => {
                                navigator.clipboard.writeText(
                                    JSON.stringify(secret, null, 2)
                                )
                                setCopy(true)
                            }}
                        >
                            <ContentCopy />
                        </IconButton>
                    </Tooltip>
                )}
            </Box>
        </Box>
    ) : (
        <Grid container component="main" sx={{ height: "100vh" }}>
            <Grid
                item
                xs={false}
                sm={4}
                md={7}
                sx={{
                    backgroundImage: ``,
                    backgroundRepeat: "no-repeat",
                    backgroundColor: (t) =>
                        t.palette.mode === "light"
                            ? t.palette.grey[50]
                            : t.palette.grey[900],
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }}
            />
            <Grid
                item
                xs={12}
                sm={8}
                md={5}
                component={Paper}
                elevation={6}
                square
            >
                <Box
                    sx={{
                        mx: 2,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        px: "2em",
                        gap: "1em",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "center",
                            mt: "2em",
                            gap: "1em",
                        }}
                    >
                        <img
                            style={{ width: "174px", height: "120px" }}
                            src={`${process.env.PUBLIC_URL}/assets/logo.png`}
                        />
                        <Typography
                            variant="h5"
                            sx={{
                                textAlign: "center",
                                fontWeight: 900,
                                mb: "0.5em",
                                mt: "-1.5em",
                            }}
                        >
                            Merchant Registration
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "1em",
                            maxHeight: `calc(100vh-500px)`,
                            overflowY: "auto",
                        }}
                    >
                        <Box
                            sx={{
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Controller
                                name="name"
                                control={control}
                                rules={{
                                    required: "Trade name is required",
                                }}
                                render={({ field }) => (
                                    <TextField
                                        variant="outlined"
                                        label="Trade Name"
                                        fullWidth
                                        margin="normal"
                                        autoComplete="name"
                                        error={!!errors?.name}
                                        helperText={
                                            errors?.name
                                                ? errors.name.message
                                                : ""
                                        }
                                        size="small"
                                        sx={{
                                            mt: 1.5,
                                            mb: 1.5,
                                        }}
                                        {...field}
                                    />
                                )}
                            />
                            <Controller
                                name="merchant_name"
                                control={control}
                                rules={{
                                    required:
                                        "Manager/Business name is required",
                                }}
                                render={({ field }) => (
                                    <TextField
                                        variant="outlined"
                                        label="Manager/Business Name"
                                        fullWidth
                                        margin="normal"
                                        autoComplete="merchant_name"
                                        error={!!errors?.merchant_name}
                                        helperText={
                                            errors?.merchant_name
                                                ? errors.merchant_name.message
                                                : ""
                                        }
                                        size="small"
                                        sx={{
                                            mt: 1.5,
                                            mb: 1.5,
                                        }}
                                        {...field}
                                    />
                                )}
                            />
                            <Controller
                                name="phone_number"
                                control={control}
                                rules={{
                                    required: "Business phone is required",
                                }}
                                render={({ field }) => (
                                    <TextField
                                        variant="outlined"
                                        label="Business Phone"
                                        fullWidth
                                        type="number"
                                        margin="normal"
                                        autoComplete="phone_number"
                                        error={!!errors?.phone_number}
                                        helperText={
                                            errors?.phone_number
                                                ? errors.phone_number.message
                                                : ""
                                        }
                                        size="small"
                                        sx={{
                                            mt: 1.5,
                                            mb: 1.5,
                                        }}
                                        {...field}
                                    />
                                )}
                            />
                            <Controller
                                name="email"
                                control={control}
                                rules={{
                                    required: "Business email is required",
                                }}
                                render={({ field }) => (
                                    <TextField
                                        variant="outlined"
                                        label="Business Email"
                                        fullWidth
                                        margin="normal"
                                        autoComplete="email"
                                        error={!!errors?.email}
                                        helperText={
                                            errors?.email
                                                ? errors.email.message
                                                : ""
                                        }
                                        size="small"
                                        sx={{
                                            mt: 1.5,
                                            mb: 1.5,
                                        }}
                                        {...field}
                                    />
                                )}
                            />
                            <Controller
                                name="address"
                                control={control}
                                rules={{
                                    required: "Business address is required",
                                }}
                                render={({ field }) => (
                                    <TextField
                                        variant="outlined"
                                        label="Business Address"
                                        fullWidth
                                        margin="normal"
                                        autoComplete="address"
                                        error={!!errors?.address}
                                        helperText={
                                            errors?.address
                                                ? errors.address.message
                                                : ""
                                        }
                                        size="small"
                                        sx={{
                                            mt: 1.5,
                                            mb: 1.5,
                                        }}
                                        {...field}
                                    />
                                )}
                            />
                        </Box>
                    </Box>
                    <Button
                        size="small"
                        variant="contained"
                        sx={{
                            bgcolor: "primary.main",
                            ":hover": { bgcolor: "primary.main" },
                            textTransform: "none",
                            fontWeight: "bold",
                            width: "100%",
                            p: "1em",
                        }}
                        type="submit"
                        onClick={handleSubmit(onSubmit)}
                    >
                        <Typography
                            variant="body1"
                            sx={{ color: "secondary.main" }}
                        >
                            Register
                        </Typography>
                        {isLoading && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: "#fff",
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    marginTop: "-12px",
                                    marginLeft: "-12px",
                                }}
                            />
                        )}
                    </Button>
                </Box>
            </Grid>
        </Grid>
    )
}
export default Registration
