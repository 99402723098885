import React, {
    useContext,
    useEffect,
    useMemo,
    useReducer,
    useRef,
} from "react"
import {
    Button,
    CircularProgress,
    Typography,
    Box,
    Grid,
    TextField,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Avatar,
    Drawer,
    Stepper,
    StepContent,
    StepLabel,
    Step,
    DialogTitle,
    Alert,
    IconButton,
} from "@mui/material"
import { Controller, useFieldArray, useForm } from "react-hook-form"
import { useMutation } from "@tanstack/react-query"
import { useSnackbar } from "notistack"
import { DateTime } from "luxon"

import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import useFetchEntities from "../../hooks/useFetchEntities"
import useFetchEntitiesPerPage from "../../hooks/useFetchEntitiesPerPage"
import useBreakPoints from "../../hooks/useBreakPoints"
import AuthContext from "../../context/authProvider"
import { Delete } from "@mui/icons-material"
import CustomDateTimePicker from "../../component/customDateTimePicker"

interface AddDiscountProps {
    open: boolean
    handleModalClose: () => void
    refetch: () => void
}
interface PriceAmount{
    amount: number,
    price_id: string
}
interface DiscountForm {
    // amount: number
    currency_id: string
    discount_code: string
    discount_type: string
    duration: string
    effective_from: string
    effective_to: string
    max_budget: number
    max_redemption: number
    max_redemption_per_user: number
    number_of_cycles: number
    payment_method_ids: string[]
    // price_ids: string[]
    price_amount: PriceAmount[]
    products: string[]
    payment_type: string
}
interface State {
    durationType: string
    activeStep: number
    validationMessage?: { status: string; message: string }
    btnMessage: string
    prevCode?: string
}

type Action =
    | { type: "SET_DURATION_TYPE"; payload: string }
    | { type: "SET_ACTIVE_STEP"; payload: number }
    | {
          type: "SET_VALIDATION_MESSAGE"
          payload?: { status: string; message: string }
      }
    | { type: "SET_BTN_MESSAGE"; payload: string }
    | { type: "SET_PREV_CODE"; payload: string | undefined }

const initialState: State = {
    durationType: "",
    activeStep: 0,
    validationMessage: undefined,
    btnMessage: "Validate",
    prevCode: undefined,
}

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case "SET_DURATION_TYPE":
            return { ...state, durationType: action.payload }
        case "SET_ACTIVE_STEP":
            return { ...state, activeStep: action.payload }
        case "SET_VALIDATION_MESSAGE":
            return { ...state, validationMessage: action.payload }
        case "SET_BTN_MESSAGE":
            return { ...state, btnMessage: action.payload }
        case "SET_PREV_CODE":
            return { ...state, prevCode: action.payload }
        default:
            return state
    }
}

function AddDiscount(props: AddDiscountProps) {
    const { merchantId } = useContext(AuthContext) as any

    const { open, handleModalClose, refetch } = props
    const [state, dispatch] = useReducer(reducer, initialState)
    const { sm } = useBreakPoints()

    const productsRef = useRef<string[]>([])
    const paymentType = useRef<string>()
    const discountCode = useRef<string | undefined>()
    const startDate = useRef<string | undefined>()    
    
    // HOOK FORM
    const {
        control,
        handleSubmit,
        formState: { errors },
        watch,
        register,
    } = useForm<DiscountForm>({
        mode: "onChange",
    })
    const { fields, append, remove } = useFieldArray({
        control,
        name: "price_amount",
      });
    productsRef.current = watch("products")
    paymentType.current = watch("payment_type")
    discountCode.current = watch("discount_code")
    startDate.current = watch("effective_from")

    const axiosPrivate = useAxiosPrivate()

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar()

    // QUERY
    const { isLoading: banksDataLoading, data: banks } = useFetchEntities({
        endPoint: "/payment-methods",
    }) as any
    const { isLoading: loading, data: currencies } = useFetchEntities({
        endPoint: "/currencies",
    }) as any
    const { isLoading: loadingProducts, data: products } =
        useFetchEntitiesPerPage({
            endPoint: "products",
            perPage: -1,
        }) as any
    // MUTATION
    const { isLoading, mutate, data } = useMutation(
        (formData) =>
            axiosPrivate.post(`/merchants/${merchantId}/discounts`, formData),
        {
            onSuccess: () =>
                enqueueSnackbar("Added a new discount successfully", {
                    variant: "success",
                }),
            onError: (error: any) => {
                const fieldError =
                    error.response?.data?.error?.field_error?.[0]?.description
                const errorMessage = error.response?.data?.error?.message
                enqueueSnackbar(
                    fieldError || errorMessage || "Request Failed",
                    {
                        variant: "error",
                    }
                )
            },
        }
    )
    const {
        isLoading: loadingValidation,
        mutate: validate,
        data: validationResponse,
    } = useMutation(
        (code: string) => axiosPrivate.patch(`discounts/exists/${code}`),
        {
            onError: (error: any) => {
                const fieldError =
                    error.response?.data?.error?.field_error?.[0]?.description
                const errorMessage = error.response?.data?.error?.message
                enqueueSnackbar(
                    fieldError || errorMessage || "Request Failed",
                    {
                        variant: "error",
                    }
                )
            },
        }
    ) as any
    // HELPERS and EVENT HANDLERS
    const validateCode = () => {
        dispatch({ type: "SET_PREV_CODE", payload: discountCode.current })
        validate(discountCode.current)
    }
    const onSubmit = (form: any) => {
        delete form?.products
        mutate({
            ...form,
            effective_from: DateTime.fromISO(form.effective_from)
                .toUTC()
                .toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'"),
            effective_to: DateTime.fromISO(form.effective_to)
                .toUTC()
                .toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'"),
        })
    }
    const handleNext = () => {
        dispatch({ type: "SET_ACTIVE_STEP", payload: state.activeStep + 1 })
    }

    const handleBack = () => {
        state.activeStep === 0
            ? handleModalClose()
            : dispatch({
                  type: "SET_ACTIVE_STEP",
                  payload: state.activeStep - 1,
              })
    }

    // USEEFFECT
    useEffect(() => {
        if (data?.data.ok) {
            refetch()
            handleModalClose()
        }
    }, [data?.data.ok])
    useEffect(() => {
        if (state.prevCode !== discountCode.current) {
            dispatch({ type: "SET_BTN_MESSAGE", payload: "Validate" })
            dispatch({ type: "SET_VALIDATION_MESSAGE", payload: undefined })
        }
        if (discountCode.current && state.prevCode === discountCode.current) {
            dispatch({ type: "SET_BTN_MESSAGE", payload: "Continue" })
        }
    }, [state.prevCode, discountCode.current])
    useEffect(() => {
        if (validationResponse?.data?.data?.exists === "YES") {
            dispatch({
                type: "SET_VALIDATION_MESSAGE",
                payload: {
                    status: "YES",
                    message:
                        "A discount code bearing this name already exists.",
                },
            })
        }
        if (validationResponse?.data?.data?.exists === "NO") {
            dispatch({
                type: "SET_VALIDATION_MESSAGE",
                payload: {
                    status: "NO",
                    message: `This code is fresh and ready to use.`,
                },
            })
        }
    }, [validationResponse])

    const prices = useMemo(
        () =>
            products?.data
                ?.filter((element: { id: string }) =>
                    productsRef.current?.includes(element.id)
                )
                ?.map((item: { prices: any }) => item.prices)
                ?.flatMap((price: any) => price),
        [productsRef.current]
    )
    // STEPPER CONTENT
    const steps = [
        {
            label: "Validate Discount Code",
            content: (
                <Grid container columnSpacing={3} sx={{ my: 1 }}>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="discount_code"
                            control={control}
                            rules={{
                                required: "Discount code can't be empty.",
                            }}
                            render={({ field }) => (
                                <TextField
                                    id="discount_code"
                                    variant="outlined"
                                    label="Code"
                                    margin="normal"
                                    fullWidth
                                    error={!!errors?.discount_code}
                                    helperText={
                                        errors?.discount_code
                                            ? errors?.discount_code?.message
                                            : null
                                    }
                                    size="small"
                                    sx={{
                                        mt: 1.5,
                                        mb: 0.5,
                                        mr: 1,
                                        flex: 1,
                                    }}
                                    {...field}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="payment_type"
                            control={control}
                            rules={{
                                required: "Payment type can't be empty.",
                            }}
                            render={({ field }) => (
                                <TextField
                                    id="payment_type"
                                    variant="outlined"
                                    label="Payment Type"
                                    margin="normal"
                                    fullWidth
                                    select
                                    error={!!errors?.payment_type}
                                    helperText={
                                        errors?.payment_type
                                            ? errors?.payment_type?.message
                                            : null
                                    }
                                    size="small"
                                    sx={{
                                        mt: 1.5,
                                        mb: 0.5,
                                        mr: 1,
                                        flex: 1,
                                    }}
                                    {...field}
                                >
                                    <MenuItem value="RECURRING">
                                        Recurring
                                    </MenuItem>
                                    <MenuItem value="ONETIME">
                                        One Time
                                    </MenuItem>
                                </TextField>
                            )}
                        />
                    </Grid>
                    {state.validationMessage && (
                        <Grid item xs={12} sm={12}>
                            <Alert
                                severity={
                                    state.validationMessage?.status === "NO"
                                        ? "success"
                                        : "warning"
                                }
                                sx={{ my: 1 }}
                            >
                                {state.validationMessage?.message}
                            </Alert>
                        </Grid>
                    )}
                </Grid>
            ),
        },
        {
            label: "Complete Form",
            content:
                loading || banksDataLoading || loadingProducts ? (
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <CircularProgress
                            size={30}
                            sx={{
                                color: "black",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                marginTop: "-12px",
                                marginLeft: "-12px",
                            }}
                        />
                    </Box>
                ) : (
                    <Grid container columnSpacing={3} sx={{ my: 1 }}>
                        <Grid item xs={12} sm={6}>
                            <FormControl
                                size="small"
                                fullWidth
                                sx={{
                                    mt: "0.7em",
                                }}
                            >
                                <InputLabel id="payment_method_ids">
                                    Payment Method(s)
                                </InputLabel>
                                <Select
                                    fullWidth
                                    {...register("payment_method_ids")}
                                    defaultValue={[]}
                                    labelId="payment_method_ids"
                                    label="Payment Method(s)"
                                    multiple
                                >
                                    {banks?.data?.map(
                                        (item: {
                                            id: string
                                            logo: string
                                            name: string
                                        }) => (
                                            <MenuItem
                                                value={item?.id}
                                                key={item?.id}
                                            >
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Avatar
                                                        sx={{
                                                            width: 30,
                                                            height: 30,
                                                            mr: 1,
                                                        }}
                                                        src={item?.logo}
                                                    />
                                                    {item.name.replace(
                                                        "_",
                                                        " "
                                                    )}
                                                </Box>
                                            </MenuItem>
                                        )
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        {paymentType.current === "RECURRING" && (
                            <Grid item xs={12} sm={6}>
                                <FormControl
                                    size="small"
                                    fullWidth
                                    sx={{
                                        mt: "0.7em",
                                    }}
                                >
                                    <InputLabel id="products">
                                        Product(s)
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        defaultValue={[]}
                                        labelId="products"
                                        label="Product(s)"
                                        multiple
                                        {...register("products")}
                                    >
                                        {products?.data?.map(
                                            (item: {
                                                id: string
                                                name: string
                                            }) => (
                                                <MenuItem
                                                    value={item?.id}
                                                    key={item?.id}
                                                >
                                                    {item.name}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                        )}
                        {paymentType.current === "RECURRING" && (
        <Grid item xs={12}>
          {fields.map((field, index) => (
            <Grid container spacing={2} key={field.id}>
              <Grid item xs={5}>
                <Controller
                  name={`price_amount.${index}.price_id`}
                  control={control}
                  render={({ field }) => (
                    <FormControl fullWidth size="small" sx={{ mt: 1.5 }}>
                      <InputLabel>Subscription Plan</InputLabel>
                      <Select {...field} label="Subscription Plan">
                        {prices.map((price: {id: string, name: string}) => (
                          <MenuItem key={price.id} value={price.id}>
                            {price.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={5}>
                <Controller
                  name={`price_amount.${index}.amount`}
                  control={control}
                  rules={{ required: "Amount is required" }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Amount"
                      type="number"
                      fullWidth
                      size="small"
                      error={!!errors.price_amount?.[index]?.amount}
                      helperText={errors.price_amount?.[index]?.amount?.message}
                      sx={{ mt: 1.5 }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <IconButton onClick={() => remove(index)} sx={{ mt: 2 }}>
                  <Delete />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          <Button 
            onClick={() => append({ price_id: '', amount: 0 })}
            variant="outlined"
            sx={{ mt: 2 }}
          >
            Add Plan Price
          </Button>
        </Grid>
      )}
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="max_budget"
                                control={control}
                                rules={{
                                    required: watch("max_redemption")
                                        ? false
                                        : "Max budget can't be empty.",
                                }}
                                render={({ field }) => (
                                    <TextField
                                        id="max_budget"
                                        variant="outlined"
                                        label="Max Budget"
                                        margin="normal"
                                        fullWidth
                                        type="number"
                                        disabled={!!watch("max_redemption")}
                                        error={!!errors?.max_budget}
                                        helperText={
                                            errors?.max_budget
                                                ? errors?.max_budget?.message
                                                : null
                                        }
                                        size="small"
                                        sx={{
                                            mt: 1.5,
                                            mb: 0.5,
                                            mr: 1,
                                            flex: 1,
                                        }}
                                        {...field}
                                        onChange={(e) =>
                                            field.onChange(
                                                parseInt(e.target.value)
                                            )
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="max_redemption"
                                control={control}
                                rules={{
                                    required: watch("max_budget")
                                        ? false
                                        : "Max number of customers can't be empty.",
                                }}
                                render={({ field }) => (
                                    <TextField
                                        id="max_redemption"
                                        variant="outlined"
                                        label="Max No. of Customers"
                                        margin="normal"
                                        fullWidth
                                        type="number"
                                        disabled={!!watch("max_budget")}
                                        error={!!errors?.max_redemption}
                                        helperText={
                                            errors?.max_redemption
                                                ? errors?.max_redemption
                                                      ?.message
                                                : null
                                        }
                                        size="small"
                                        sx={{
                                            mt: 1.5,
                                            mb: 0.5,
                                            mr: 1,
                                            flex: 1,
                                        }}
                                        {...field}
                                        onChange={(e) =>
                                            field.onChange(
                                                parseInt(e.target.value)
                                            )
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="max_redemption_per_user"
                                control={control}
                                rules={{
                                    required:
                                        "Max redemption per user can't be empty.",
                                }}
                                render={({ field }) => (
                                    <TextField
                                        id="max_redemption_per_user"
                                        variant="outlined"
                                        label="Max Redemption Per User"
                                        margin="normal"
                                        fullWidth
                                        type="number"
                                        error={
                                            !!errors?.max_redemption_per_user
                                        }
                                        helperText={
                                            errors?.max_redemption_per_user
                                                ? errors
                                                      ?.max_redemption_per_user
                                                      ?.message
                                                : null
                                        }
                                        size="small"
                                        sx={{
                                            mt: 1.5,
                                            mb: 0.5,
                                            mr: 1,
                                            flex: 1,
                                        }}
                                        {...field}
                                        onChange={(e) =>
                                            field.onChange(
                                                parseInt(e.target.value)
                                            )
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={state.durationType === "REPEATING" ? 6 : 12}
                            sm={state.durationType === "REPEATING" ? 3 : 6}
                        >
                            <FormControl
                                size="small"
                                fullWidth
                                sx={{
                                    mt: "0.7em",
                                }}
                            >
                                <InputLabel id="duration">Duration</InputLabel>
                                <Select
                                    fullWidth
                                    {...register("duration")}
                                    labelId="duration"
                                    label="Duration"
                                    MenuProps={{
                                        sx: {
                                            "&& .Mui-selected": {
                                                backgroundColor: "primary.main",
                                                color: "#fff",
                                            },
                                        },
                                    }}
                                    value={state.durationType}
                                    onChange={(event: {
                                        target: { value: any }
                                    }) => {
                                        dispatch({
                                            type: "SET_DURATION_TYPE",
                                            payload: event.target.value,
                                        })
                                    }}
                                >
                                    <MenuItem value="ONCE">Once</MenuItem>
                                    {/* <MenuItem value="REPEATING">
                                        Repeating
                                    </MenuItem>
                                    <MenuItem value="FOREVER">Forever</MenuItem> */}
                                </Select>
                            </FormControl>
                        </Grid>
                        {state.durationType === "REPEATING" && (
                            <Grid item xs={6} sm={3}>
                                <Controller
                                    name="number_of_cycles"
                                    control={control}
                                    rules={{
                                        required:
                                            state.durationType === "REPEATING"
                                                ? "No. of cycles can't be empty."
                                                : false,
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            id="number_of_cycles"
                                            variant="outlined"
                                            label="Number of Cycles"
                                            margin="normal"
                                            fullWidth
                                            type="number"
                                            error={!!errors?.number_of_cycles}
                                            helperText={
                                                errors?.number_of_cycles
                                                    ? errors?.number_of_cycles
                                                          ?.message
                                                    : null
                                            }
                                            size="small"
                                            sx={{
                                                mt: 1.5,
                                                mb: 0.5,
                                                mr: 1,
                                                flex: 1,
                                            }}
                                            {...field}
                                            onChange={(e) =>
                                                field.onChange(
                                                    parseInt(e.target.value)
                                                )
                                            }
                                        />
                                    )}
                                />
                            </Grid>
                        )}
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="effective_from"
                                control={control}
                                rules={{
                                    required: "Start date can't be empty.",
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <CustomDateTimePicker
                                        onChange={onChange}
                                        label="Start Date"
                                        value={value}
                                        date2={DateTime.now().toISO()}
                                        margin="normal"
                                        size="small"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="effective_to"
                                control={control}
                                rules={{
                                    required: "End date can't be empty.",
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <CustomDateTimePicker
                                        onChange={onChange}
                                        label="End Date"
                                        value={value}
                                        date2={startDate.current}
                                        margin="normal"
                                        size="small"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="discount_type"
                                control={control}
                                rules={{
                                    required: "Discount type can't be empty.",
                                }}
                                render={({ field }) => (
                                    <TextField
                                        id="discount_type"
                                        variant="outlined"
                                        label="Discount Type"
                                        margin="normal"
                                        fullWidth
                                        select
                                        error={!!errors?.discount_type}
                                        helperText={
                                            errors?.discount_type
                                                ? errors?.discount_type?.message
                                                : null
                                        }
                                        size="small"
                                        sx={{
                                            mt: 1.5,
                                            mb: 0.5,
                                            mr: 1,
                                            flex: 1,
                                        }}
                                        {...field}
                                    >
                                        <MenuItem value="FLAT">Amount</MenuItem>
                                        <MenuItem value="PERCENT">
                                            Percent
                                        </MenuItem>
                                    </TextField>
                                )}
                            />
                        </Grid>
                        {/* <Grid item xs={6} sm={6}>
                            <Controller
                                name="amount"
                                control={control}
                                rules={{
                                    required: "Amount/Percent can't be empty.",
                                }}
                                render={({ field }) => (
                                    <TextField
                                        id="amount"
                                        variant="outlined"
                                        label="Amount/Percent"
                                        margin="normal"
                                        type="number"
                                        fullWidth
                                        error={!!errors?.amount}
                                        helperText={
                                            errors?.amount
                                                ? errors?.amount?.message
                                                : null
                                        }
                                        size="small"
                                        sx={{
                                            mt: 1.5,
                                            mb: 0.5,
                                            mr: -2,
                                            flex: 2,
                                        }}
                                        {...field}
                                        onChange={(e) =>
                                            field.onChange(
                                                parseInt(e.target.value)
                                            )
                                        }
                                    />
                                )}
                            />
                        </Grid> */}
                        <Grid item xs={6} sm={6}>
                            <Controller
                                name="currency_id"
                                control={control}
                                rules={{
                                    required: "Currency can't be empty.",
                                }}
                                render={({ field }) => (
                                    <TextField
                                        id="currency_id"
                                        variant="outlined"
                                        label="Currency"
                                        margin="normal"
                                        fullWidth
                                        select
                                        error={!!errors?.currency_id}
                                        helperText={
                                            errors?.currency_id
                                                ? errors?.currency_id?.message
                                                : null
                                        }
                                        size="small"
                                        sx={{
                                            mt: 1.5,
                                            mb: 0.5,
                                            mr: 1,
                                            flex: 1,
                                        }}
                                        {...field}
                                    >
                                        {currencies?.data?.map((item: any) => (
                                            <MenuItem
                                                value={item?.id}
                                                key={item?.id}
                                            >
                                                {item.code}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                )}
                            />
                        </Grid>
                    </Grid>
                ),
        },
    ]

    // RENDER
    return (
        <Drawer
            open={open}
            anchor="right"
            onClose={handleModalClose}
            PaperProps={{
                style: {
                    width: sm ? "50%" : "100%",
                },
            }}
        >
            <DialogTitle
                sx={{
                    display: "flex",
                    justifyContent: "flex-startstart",
                    bgcolor: "#fafafa",
                }}
            >
                <Box sx={{ ml: "0.8em" }}>
                    <Typography
                        sx={{
                            fontSize: 20,
                            fontWeight: "bold",
                            textAlign: "center",
                        }}
                    >
                        {" "}
                        Add Discount
                    </Typography>
                </Box>
            </DialogTitle>
            <Stepper
                activeStep={state.activeStep}
                orientation="vertical"
                sx={{ mx: 4, my: 2 }}
            >
                {steps.map((step, index) => (
                    <Step key={step.label}>
                        <StepLabel
                            optional={
                                index === steps.length - 1 ? (
                                    <Typography variant="caption">
                                        Last step
                                    </Typography>
                                ) : (
                                    <Typography variant="caption">
                                        Initial step
                                    </Typography>
                                )
                            }
                        >
                            {step.label}
                        </StepLabel>
                        <StepContent>
                            <Typography>{step.content}</Typography>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                }}
                            >
                                {index !== steps.length - 1 ? (
                                    <Button
                                        size="small"
                                        variant="contained"
                                        type={
                                            state.btnMessage !== "Continue"
                                                ? "submit"
                                                : "button"
                                        }
                                        disabled={
                                            loadingValidation ||
                                            discountCode.current ===
                                                undefined ||
                                            paymentType.current === undefined
                                        }
                                        onClick={() =>
                                            state.btnMessage === "Validate"
                                                ? validateCode()
                                                : handleNext()
                                        }
                                        sx={{
                                            bgcolor: "primary.main",
                                            ":hover": {
                                                bgcolor: "primary.main",
                                            },
                                            ":disabled": {
                                                bgcolor: "primary.main",
                                            },
                                            textTransform: "none",
                                            fontWeight: "bold",
                                            width: 120,
                                            p: 1,
                                            mr: 1,
                                            my: 1,
                                        }}
                                    >
                                        <Typography
                                            sx={{ color: "secondary.main" }}
                                        >
                                            {state.btnMessage}
                                        </Typography>
                                        {loadingValidation && (
                                            <CircularProgress
                                                size={24}
                                                sx={{
                                                    color: "black",
                                                    position: "absolute",
                                                    top: "50%",
                                                    left: "50%",
                                                    marginTop: "-12px",
                                                    marginLeft: "-12px",
                                                }}
                                            />
                                        )}
                                    </Button>
                                ) : (
                                    <Button
                                        size="small"
                                        variant="contained"
                                        type="submit"
                                        disabled={isLoading}
                                        onClick={handleSubmit(onSubmit)}
                                        sx={{
                                            bgcolor: "primary.main",
                                            ":hover": {
                                                bgcolor: "primary.main",
                                            },
                                            ":disabled": {
                                                bgcolor: "primary.main",
                                            },
                                            textTransform: "none",
                                            fontWeight: "bold",
                                            width: 120,
                                            p: 1,
                                            mr: 1,
                                            my: 1,
                                        }}
                                    >
                                        <Typography
                                            sx={{ color: "secondary.main" }}
                                        >
                                            Finish
                                        </Typography>
                                        {isLoading && (
                                            <CircularProgress
                                                size={24}
                                                sx={{
                                                    color: "black",
                                                    position: "absolute",
                                                    top: "50%",
                                                    left: "50%",
                                                    marginTop: "-12px",
                                                    marginLeft: "-12px",
                                                }}
                                            />
                                        )}
                                    </Button>
                                )}
                                <Button
                                    size="small"
                                    variant="contained"
                                    onClick={handleBack}
                                    sx={{
                                        ":hover": { bgcolor: "#e4e4e4" },
                                        backgroundColor: "#e4e4e4",
                                        textTransform: "none",
                                        fontWeight: "bold",
                                        width: 120,
                                        p: 1,
                                        my: 1,
                                    }}
                                >
                                    <Typography sx={{ color: "primary.main" }}>
                                        Back
                                    </Typography>
                                </Button>
                            </Box>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
        </Drawer>
    )
}
export default AddDiscount
